<script setup>
    import { ref, onMounted } from 'vue';
    import { helpers } from '../../helpers';

    const emit = defineEmits(['onItemStatusChanged']);

    const _TRANSLATION = window._TRANSLATION;

    const props = defineProps(['items']);

    const paginatedDrops = ref(props.items['items'].data);
    const paginatedPages = ref(props.items['links']);

    onMounted( () => {
        // Paginate
        $(document).on('click', '#pagination li', function (e) {
            e.preventDefault();
            helpers.paginate(this, (data) => {
                paginatedDrops.value = data.items.data;
                paginatedPages.value = data.links;
            });
        });
    } );
</script>

<template>

    <div class="subtitle">
        <img src="/assets/images/line.webp" alt="" class="img-adaptive" loading="lazy">
        <h2>{{ helpers.translate(_TRANSLATION.views.contract.create.items.available) }}</h2>
        <img src="/assets/images/line.webp" alt="" class="img-adaptive" loading="lazy">
    </div>

    <div class="no-items" v-if="Object.keys(paginatedDrops).length < 1">
        <p class="text-error">{{ helpers.translate(_TRANSLATION.views.contract.create.items.empty) }}</p>
        <a href="/" class="link-clear">
            <button class="btn btn_action">{{ helpers.translate(_TRANSLATION.views.contract.create.items.open) }}</button>
        </a>
    </div>
    <div class="case-items" v-else>
        <!-- <div class="case-item" :data-price="value.item_price['amount_sell']" :data-currency="value.item_price['currency']" :data-id="value.drop_id" :class='"rare_"+value.rarity' v-for="(value, index) in paginatedDrops" @click="$emit('onItemStatusChanged', $event)">
            <img :src='value.image' alt="" class="img-adaptive" loading="lazy">
            <div class="case-item-details">
                <p class="weapon-name">{{ value.weapon.name }}</p>
                <p class="weapon-details">{{ value.weapon.details }}</p>
            </div>
        </div> -->
        <div class="case-item" :data-price="value.item_price['amount_sell']" :class='"rare_"+value.rarity' :data-currency="value.item_price['currency']" :data-id="value.drop_id" v-for="(value, index) in paginatedDrops" @click="$emit('onItemStatusChanged', $event)" >
            <img :src='value.image' alt="" class="img-adaptive" loading="lazy">
            <div class="case-item-details">
                <p class="weapon-name">{{ value.weapon.name }} <span class="price">{{ value.item_price['amount_sell'] }} <span class="currency">{{ value.item_price['currency'] }}</span></span></p>
                <p class="weapon-details">{{ value.weapon.details }}</p>
            </div>
        </div>
    </div>
    <div id="pagination" v-html="paginatedPages" class="pagination-gap"></div>
</template>

