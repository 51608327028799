<script setup>
    import { ref } from 'vue';

    // Props
    const props = defineProps(['items']);

    let _LANG = window._LANG;
    if(_LANG == 'ru') _LANG = ''; // Default 'ru' lang (HIDE IN URL)

    const _TRANSLATION = window._TRANSLATION;

    const csrf = $('input[name="_token"]').val();   // CSRF Token

    // Refs
    const activeIndexes = ref([]);

    function toggle(element, index) {
        let target = element.target;

        if( target.classList.contains('accordion__answer') || target.classList.contains('input-clear') || target.classList.contains('btn_action') ) return; // Skip click on `answer`

        let accordion = target.closest('.accordion');

        if( !accordion.classList.contains('active') ) {
            accordion.classList.add('active');
            activeIndexes.value.push(index);
        } else {
            accordion.classList.remove('active');
            let activeIndex = activeIndexes.value.indexOf(index);
            activeIndexes.value.splice(activeIndex, 1);
        }
    }
</script>

<template>
    <div class="accordion" @click="toggle($event, index)" v-for="(value, index) in props.items">
        <div class="accordion__status" v-if="activeIndexes.includes(index)">-</div>
        <div class="accordion__status" v-else>+</div>
        <div class="accordion__body">
            <p class="accordion__question">{{ value.question }}</p>
        </div>
        <div class="accordion__answer" v-if="activeIndexes.includes(index)">{{ value.answer }}</div>
    </div>

    <div class="accordion" @click="toggle($event, 999999)">
        <div class="accordion__status" v-if="activeIndexes.includes(index)">-</div>
        <div class="accordion__status" v-else>+</div>
        <div class="accordion__body">
            <p class="accordion__question">{{ _TRANSLATION.views.faq.contact_us }}</p>
        </div>
        <div class="accordion__answer" v-if="activeIndexes.includes(999999)">
            <form class="feedback" :action='_LANG + "/feedback/request"' method="POST">
                <input type="email" class="input input-clear" name="email" :placeholder='_TRANSLATION.views.faq.placeholder_email' required>
                <textarea class="input input-clear" name="message" :placeholder='_TRANSLATION.views.faq.message' rows="5" min="10" required></textarea>
                <input type="hidden" name="_token" :value="csrf">
                <button class="btn btn_action">{{ _TRANSLATION.views.faq.send }}</button>
            </form>
        </div>
    </div>
</template>
