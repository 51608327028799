<script setup>
    import { ref, reactive, onMounted, nextTick } from 'vue';
    import { helpers } from '../../helpers';

    const emit = defineEmits(['onSkinGiveChoosen']);
    const props = defineProps(['drops', 'totalSelected', 'watchChoosenSkins']);

    const paginatedDrops = ref(props.drops['items'].data);
    const paginatedPages = ref(props.drops['links']);

    // Paginate
    onMounted(() => {
        nextTick(() => {
            $(document).off('click', '#pagination li').on('click', '#pagination li', function (e) {
                e.preventDefault();
                helpers.paginate(this, (data) => {
                    paginatedDrops.value = data.items.data;
                    paginatedPages.value = data.links;
                    props.watchChoosenSkins('.upgrade__list-your', props.totalSelected.items.give);
                });
            });
        })
    });

</script>

<template>
    <div class="case-items">
        <div class="case-item" :data-id="value.drop_id" :price="value.item_price['amount_sell']" :class='"rare_"+value.rarity' v-for="(value, index) in paginatedDrops" @click="$emit('onSkinGiveChoosen', $event)" >
            <img :src='value.image' alt="" class="img-adaptive" loading="lazy">
            <div class="case-item-details">
                <p class="weapon-name">{{ value.weapon.name }} <span class="price">{{ value.item_price['amount_sell'] }} <span class="currency">{{ value.item_price['currency'] }}</span></span></p>
                <p class="weapon-details">{{ value.weapon.details }}</p>
            </div>
        </div>
    </div>

    <div id="pagination" v-html="paginatedPages" class="pagination-gap"></div>
</template>
