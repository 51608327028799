import './app.js';

import AOS from 'aos';

import {Howl, Howler} from 'howler';

let soundCookie = helpers.getCookie('sound_enabled');

if( soundCookie != null && soundCookie == 1 ) {
    window.startSong = new Howl({
        src: ['/assets/sounds/start.mp3'],
    });

    window.processSong = new Howl({
        src: ['/assets/sounds/process.mp3']
    });

    window.resultSong = new Howl({
        src: ['/assets/sounds/result.mp3']
    });
}


// Init AOS animations
AOS.init();

////////////////////////////////
// Init Vue Global Components //

/* Live line */
import Live from './components/Live.vue';
const live = createApp({});
live.component('live-index', Live);
live.mount('#live');

/* Cases List (Main Page) */
import CasesList from './components/Cases/CasesList.vue';
//const casesList = createApp(CasesList);
const casesList = createApp({});
casesList.component('cases-list', CasesList);
casesList.mount('#cases-list');

/* FAQ Page */
import FAQ from './components/FAQ.vue';
const faq = createApp({});
faq.component('faq-index', FAQ);
faq.mount('#faq');

/* Add Money Page */
import AddMoney from './components/AddMoney.vue';
const addMoney = createApp({});
addMoney.component('payment-index', AddMoney);
addMoney.mount('#payment');

/* Your drops */
import CasesDropsProfile from './components/Cases/CasesDropsProfile.vue';
const drops = createApp({});
drops.component('drops-index', CasesDropsProfile);
drops.mount('#your-drops');

/* Open case */
import CasesOpen from './components/Cases/CasesOpen.vue';
const casesOpen = createApp({});
casesOpen.component('case-index', CasesOpen);
casesOpen.mount('#opencase');

/* Contract */
import Contract from './components/Contract/Contract.vue';
const contract = createApp({});
contract.component('contract-index', Contract);
contract.mount('#contract-management');

/* Upgrade */
import Upgrade from './components/Upgrade/Upgrade.vue';
import UpgradeStore from './store/upgrade_store.js'; // Upgrade Store (Vuex)
import { helpers } from './helpers.js';
//import { helpers } from './helpers.js';
const upgrade = createApp({});
upgrade.use(UpgradeStore);
upgrade.component('upgrade-index', Upgrade);
upgrade.mount('#upgrade-management');
////////////////////////////////


// Init language select
$('#language').select2({
    minimumResultsForSearch: -1, // Disable search
    width: '65px',
});

// On language changed
$(document).on('change', '#language', function () {
    let url = $('#language').find(':selected').data('url');
    if(url == null) {
        alert('Error occured during lang change!');
        return;

    }
    window.location.replace(url);
});

// Init currency select
if( $('#currency').length > 0 ) {
    $('#currency').select2({
        minimumResultsForSearch: -1, // Disable search */
        width: '60px',
    });
    $('#currency').data('select2').$container.addClass('select-currency');
}

if( $('#currency-profile').length > 0 ) {
    $('#currency-profile').select2({
        minimumResultsForSearch: -1, // Disable search */
        width: '60px',
    });
    $('#currency-profile').data('select2').$container.addClass('profile-currency');
}

// On currency changed
$(document).on('change', '#currency, #currency-profile', function () {
    let value = $(this).val();
    $.post(`/change/currency/${value}`, {},
        function (data, textStatus, jqXHR) {
            if(data.error != null) {
                helpers.showError(data.error);
                return;
            }

            location.reload();
        }
    );
});


// Mobile menu (burger)
$(document).on('click', '#burger', function () {
    if( $(this).hasClass('active') ){
        $(this).removeClass('active');
        $('.header__nav').fadeOut(300);
        $('.header__account').fadeOut(300);
    } else {
        $(this).addClass('active');
        $('.header__nav').css("display", "flex").hide().fadeIn(300);
        $('.header__account').css("display", "flex").hide().fadeIn(300);
    }
});

// On case amount to open choosen
$(document).on('click', '#choice li', function () {
    $('#choice li').removeClass('active');
    $(this).addClass('active');
});

// [ACTION] Save trade URL
$(document).on('click', '#save-trade_url', function () {
    let linkSelector = $(this).parent().find('[name="trade_link"]');
    if(linkSelector == null){
        helpers.showError('Не удалось обнаружить DOM-элемент для ссылки!');
        return;
    }

    let link = $(linkSelector).val();
    if(link == '') {
        helpers.showError('Ссылка не может быть пустой!');
        return;
    }

    if( !link.includes('https://steamcommunity.com/tradeoffer/new/?partner=') ){
        helpers.showError('Неверный формат ссылки!');
        return;
    }

    $.post("/profile/update/trade_url", {link},
        function (data, textStatus, jqXHR) {
            if(data.error != null){
                helpers.showError(data.error);
                return;
            }

            helpers.showMessage(data.success);
        },
    );
});

// [ACTION] Copy referal code
$(document).on('click', '#copy-referal', function () {
    let code = helpers.copy('referal');
    if(code) helpers.showMessage('Вы успешно скопировали промокод!');
});

// [ACTION] ON/OFF Sound
$(document).on('click', '#manage-sound', function () {
    let enabled = $(this).attr('data-enabled');
    if(enabled == 1){
        $(this).find('img[data-enabled="1"]').fadeOut(200, () => {
            $(this).find('img[data-enabled="0"]').fadeIn(200);
            $(this).attr('data-enabled', 0);
            helpers.setCookie('sound_enabled', 0, 365);
            delete window.startSong;
            delete window.processSong;
            delete window.resultSong;
        });
    } else {
        $(this).find('img[data-enabled="0"]').fadeOut(200, () => {
            $(this).find('img[data-enabled="1"]').fadeIn(200);
            $(this).attr('data-enabled', 1);
            helpers.setCookie('sound_enabled', 1, 365);
            window.startSong = new Howl({
                src: ['/assets/sounds/start.mp3'],
            });

            window.processSong = new Howl({
                src: ['/assets/sounds/process.mp3']
            });

            window.resultSong = new Howl({
                src: ['/assets/sounds/result.mp3']
            });
        });
    }
});
