<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { helpers } from '../../helpers';

    const store = useStore();

    const emit = defineEmits(['onSkinChoosen']);
    const props = defineProps(['items', 'multiplier', 'balance', 'totalSelected', 'watchChoosenSkins']);

    /* Refs */
    const multiplier = ref(props.multiplier);
    const items = ref(props.items);

    console.log(store.getters.getAvailableItems);

    //const paginatedDrops = ref(store.getters.getAvailableItems.items.data);

    const paginatedDrops = ref(store.state.availableItems.items.data);
    const paginatedPages = ref(store.state.availableItems.links);

    onMounted(() => {
        // Paginate
        $(document).off('click', '#pagination-receive li').on('click', '#pagination-receive li', function (e) {
            console.log(items.value);
            e.preventDefault();
            helpers.paginate(this, (data) => {
                paginatedDrops.value = data.items.data;
                paginatedPages.value = data.links;
                props.watchChoosenSkins('.upgrade__list-proposition', props.totalSelected.items.receive);
            }, {items: Object.keys(items.value), multiplier: multiplier.value, balance: props.balance});
        });
    });

</script>

<template>
    <div class="case-items">
       <div class="case-item" :data-id="value.id" :price="value.price_global['amount_sell']" :class='"rare_"+value.rarity' v-for="(value, index) in paginatedDrops" @click="$emit('onSkinChoosen', $event, 'receive')" >
            <img :src='value.image_generated' alt="" class="img-adaptive" loading="lazy">
            <div class="case-item-details">
                <p class="weapon-name">{{ value.weapon.name }} <span class="price">{{ value.price_global['amount_sell'] }} <span class="currency">{{ value.price_global['currency'] }}</span></span></p>
                <p class="weapon-details">{{ value.weapon.details }}</p>
            </div>
        </div>
    </div>

    <div id="pagination-receive" v-html="paginatedPages" class="pagination-wrapper pagination-gap"></div>
</template>
