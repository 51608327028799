<script setup>
    import { onMounted } from 'vue';
    import { helpers } from '../../helpers';

    const props = defineProps(['blocks', 'user']);

    let _LANG = window._LANG;
    if(_LANG == 'ru') _LANG = ''; // Default 'ru' lang (HIDE IN URL)
    const _TRANSLATION = window._TRANSLATION;

    function mouseOver(element) {
        element.target.closest('.case').classList.add('active');
    }

    function mouseLeave(element) {
        element.target.closest('.case').classList.remove('active');
    }

    onMounted(() => {
        $('.loader').remove();
    });
</script>

<template>
    <transition name="fade" appear>
        <div>
            <div class="cases-group" v-for="(value, index) in blocks">
                <p class="cases-group__title">{{ value.lang_name }}</p>

                <div class="cases-group__list">
                    <a :href='`${_LANG}/case/${v.id}`' class="link-clear" v-for="(v, i) in value.cases">
                        <div class="case" :class="v.type == 1 ? 'case_additional' : ''" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)">
                            <img :src="v.image" alt="" class="img-adaptive case-img" loading="lazy">
                            <div class="case__info">
                                <p class="case__name">{{ v.lang_name }}</p>

                                <button class="btn btn_action" v-if="v.is_free == 1 && user && !user.steam_changed">{{ _TRANSLATION.views.index.case_free.add_to_nick }} {{ user.nick_needed }}</button>

<!--                                 <button class="btn btn_action btn_action_small" v-else>
                                    <span v-if="v.is_free">{{ _TRANSLATION.views.index.case_free.free }}</span>
                                    <span v-else>
                                        <span class="price">{{ v.price_global['amount'] }}</span> {{ v.price_global['currency'] }}
                                    </span>
                                </button> -->
                                <button class="btn btn_action w-100" v-else-if="v.is_free">
                                    {{ _TRANSLATION.views.index.case_free.free }}
                                </button>
                                <button class="btn btn_action btn_action_small" v-else>
                                    <span class="price">{{ v.price_global['amount'] }}</span> {{ v.price_global['currency'] }}
                                </button>

                                <div class="hover-line"></div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>
