import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            // Added Items
            availableItems: {}
        }
    },
    mutations: {
        // Fill items
        fill(state, items){
            state.availableItems = items;
        }
    },
    getters: {
        getAvailableItems(state) { return state.availableItems }
    }
});
