<script setup>

import { helpers } from '../../helpers';
const _TRANSLATION = window._TRANSLATION;

const props = defineProps(['items']);

</script>

<template>
    <div id="case-drops">
        <div class="subtitle">
            <img src="../../../images/line.webp" alt="" class="img-adaptive" loading="lazy">
            <h2>{{  helpers.translate(_TRANSLATION.views.case.items_title)  }}</h2>
            <img src="../../../images/line.webp" alt="" class="img-adaptive" loading="lazy">
        </div>

        <div class="case-items">
            <div class="case-item" :class='"rare_"+value.rarity' v-for="(value, index) in items">
                <!-- <img v-if='value.is_custom' :src='value.image' alt="" class="img-adaptive" loading="lazy">
                <img v-else :src='"https://cdn.csgo.com/item/"+value.market_hash_name+"/100.png"' alt="" class="img-adaptive" loading="lazy"> -->
                <img :src='value.image' alt="" class="img-adaptive" loading="lazy">
                <div class="case-item-details">
                    <p class="weapon-name">{{ value.weapon.name }}</p>
                    <p class="weapon-details">{{ value.weapon.details }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
