<script setup>
import { onMounted, nextTick, ref, reactive } from 'vue';
import { helpers } from '../../helpers';

import CasesOpenDrops from './CasesOpenDrops.vue';

const _LANG = window._LANG;
const _TRANSLATION = window._TRANSLATION;

const props = defineProps(['items', 'current_case', 'authed', 'user']);

console.log(props.items);

// Refs
const openStarted = ref(false);
const openFinished = ref(false);
const rouletteSpinned = ref(false);
const error = ref(false);
const itemsFilled = ref([]);
const lastWinItems = ref([]);
const wins = ref([]);
const dropsSaved = ref({});

let openAmount = ref(1);

// Width of case item in roulette: 166px
const MAX_IN_RULETTE = 256;
let ROULETTE_ITEM_WIDTH = (window.screen.width > 1366) ? 165 : 114;    // 166 - 1 (includes gap)

/* Open case */
const open = (type) => {

    if(typeof startSong != 'undefined') startSong.play();

    if (!props.authed) {
        helpers.showError( helpers.translate(_TRANSLATION.views.case.open.not_authed.text) );
        return;
    }

    $.post(`/case/${props.current_case.id}/open`, { amount: openAmount.value },
        function (data, textStatus, jqXHR) {
            console.log(data);
            if (data.error != null) {
                if (data.error.critical != null) {
                    error.value = {
                        title: data.error.title,
                        message: data.error.message
                    }
                } else {
                    helpers.showError(data.error);
                }
                return;
            }

            for (const [key, value] of Object.entries(data.wins)) {
                fillItems(key, value);
                dropsSaved.value[key] = {
                    id: data.drops_saved[key],
                    sold: false
                }
            }

            console.log(wins.value);
            //console.log(dropsSaved.value);


            nextTick( () => {
                helpers.changeBalance(data.new_balance);
            } );


            // If quick open
            if(type == 'quick'){
                /* $('.case-body').fadeOut(300, function(){
                    openFinished.value = true;
                }); */

                if(typeof resultSong != 'undefined') resultSong.play();

                /* setTimeout( () => {
                    openStarted.value = true;
                    openFinished.value = true;
                }, 1000 ); */
                openStarted.value = true;
                openFinished.value = true;

                return;
            }

            $('.case-body').slideUp(300);
            openStarted.value = true;
            rouletteSpinned.value = true;

            nextTick(() => {

                let i = 1;
                setTimeout(() => {
                    /* For each of added roulettes */

                    if(typeof processSong != 'undefined') processSong.play();

                    $('.roulette').each(function (index, element) {

                        let rouletteWinItem = ++lastWinItems.value[i];

                        //$(element).find(`.case-roulette__item:nth-of-type(${rouletteWinItem})`).addClass('winner');

                        let rouletteWidth = element.offsetWidth;
                        let marginLeft = `-${(rouletteWinItem * ROULETTE_ITEM_WIDTH) - (rouletteWidth / 2) + 25}`;

                        $(element).find('.case-roulette__spinner').animate({
                            'margin-left': marginLeft
                        }, 5000, 'swing', () => {
                            //$('#case-drops').fadeOut(400);

                            // If last roulette finished
                            if (index == (openAmount.value - 1)) {
                                setTimeout(() => {
                                    $('#case-drops').css('display', 'none');

                                    if(typeof processSong != 'undefined') processSong.stop();
                                    if(typeof resultSong != 'undefined') resultSong.play();

                                    rouletteSpinned.value = false;
                                    openFinished.value = true;

                                }, 1000);
                            }

                        });

                        i++;
                        if(i > lastWinItems.value.length) return;
                    });
                }, 1500);
            });
        },
        "json"
    );

};

/* Fill items to roulette */
const fillItems = (index, value) => {
    itemsFilled.value[index] = [];
    for (let i = 0; i < MAX_IN_RULETTE; i++) {
        let randIndex = Math.floor(Math.random() * props.items.length);
        itemsFilled.value[index].push(props.items[randIndex]);
    }

    let winIndex = findIndexById(value);

    itemsFilled.value[index][30] = props.items[winIndex];

    let winItem = props.items[winIndex];
    wins.value[index] = winItem;

    let slicedItems = itemsFilled.value[index].slice(0, 40);
    slicedItems[30] = winItem;
    let lastWinItem = slicedItems.map(e => e.id).lastIndexOf(winItem.id);
    lastWinItems.value[index] = lastWinItem;
};

/* Reset case */
const resetCase = () => {
    openAmount.value = 1;
    //$('#case-drops').css('display', 'block');
    openStarted.value = false;
    openFinished.value = false;
    rouletteSpinned.value = false;
    /* nextTick(() => {
        //$('#case-drops').fadeIn(100, () => {
            //rouletteSpinned.value = false;
           //openFinished.value = false;
            //openStarted.value = false;
        //});
        //$('#case-drops').fadeIn(500);
        //$('#case-drops').css('display', 'block');
    }); */
};

/* Change open amount */
const changeOpenAmount = (event) => {
    let clickedSelector = event.target;
    let amount = Number.parseInt(clickedSelector.getAttribute('amount'));
    openAmount.value = (amount == null || amount > 10) ? 1 : amount; // Max 10 cases per once
};

/* Find item in list by id */
const findIndexById = (id) => {
    for (let index in props.items) {
        if (props.items[index].id == id) return index;
    }

    return null;
}

// Sell item
const sell = (id, index) => {
    $.post(`/item/sell/${id}`, {},
        function (data, textStatus, jqXHR) {
            if(data.error != null){
                helpers.showError(data.error);
                return;
            }

            //helpers.showMessage(data.success.message);
            dropsSaved.value[index].sold = true;
            helpers.changeBalance(data.success.new_balance);
        }
    );
};

</script>

<template>
    <transition name="slide-down" mode="out-in">
        <div v-if="!openStarted">
            <div class="case-body">
                <img :src='current_case.image' alt="" class="img-adaptive case-img" loading="lazy">


                <div class="error error_warning" v-if="!authed">
                    <p class="error__title">{{ helpers.translate(_TRANSLATION.views.case.open.not_authed.title) }}</p>
                    <p class="error__message">{{ helpers.translate(_TRANSLATION.views.case.open.not_authed.text) }}</p>
                </div>

                <div class="error error_warning" v-else-if="authed && current_case.is_free && !user.steam_changed">
                    <p class="error__title">{{ helpers.translate(_TRANSLATION.views.case.open.add_to_nick.title) }}</p>
                    <p class="error__message">{{ helpers.translate(_TRANSLATION.views.case.open.add_to_nick.text_1) }} <strong>{{ user.nick_needed }}</strong> {{ _TRANSLATION.views.case.open.add_to_nick.text_2 }}</p>
                </div>

                <div class="error error_warning" v-else-if="authed && current_case.is_free && user.steam_changed && !user.can_open_free.permission">
                    <p class="error__title">{{ helpers.translate(_TRANSLATION.views.case.open.timeout_open.wait_for) }} <strong>{{ user.can_open_free.time_left }}</strong></p>
                    <p class="error__message">{{ helpers.translate(_TRANSLATION.views.case.open.timeout_open.text) }}</p>
                </div>


                <transition name="fade-fast">
                    <div class="error error_critical" v-if="error">
                        <p class="error__title">{{ error.title }}</p>
                        <p class="error__message">{{ error.message }}</p>
                    </div>
                </transition>

            </div>

            <div v-if="!current_case.is_free">
                <div class="case-open" v-if="authed">
                    <div class="case-open__left">
                        <ul class="ul-inline open-amount" id="choice" @click="changeOpenAmount($event)">
                            <li class="active" :amount="1">x1</li>
                            <li :amount="2">x2</li>
                            <li :amount="3">x3</li>
                            <li :amount="5">x5</li>
                            <li :amount="10">x10</li>
                        </ul>
                    </div>

                    <div class="case-open__right">
                        <button class="btn btn_action btn_open" @click="open('full')"><span class="price">{{ helpers.translate(_TRANSLATION.views.case.open.open_for) }}
                                {{ parseFloat(current_case.price_global['amount'] * openAmount).toFixed(2) }}</span> {{ current_case.price_global['currency'] }}</button>
                        <button class="btn btn_action btn_open_quick" @click="open('quick')">{{ helpers.translate(_TRANSLATION.views.case.open.open_quick) }}</button>
                    </div>
                </div>

                <a href="/steam/auth" class="link-clear" v-else>
                    <button class="btn btn_action btn_action_flex" id="steam-auth-black">{{ helpers.translate(_TRANSLATION.views.case.open.not_authed.steam_auth) }} <img
                            src="../../../images/steam_black.svg" alt="" class="img-adaptive"></button>
                </a>
            </div>
            <div v-else-if="current_case.is_free && authed && user.steam_changed">
                <div class="case-open" v-if="user.can_open_free.permission">
                    <div class="case-open__right" style="justify-content: center;">
                        <button class="btn btn_action btn_open" @click="open('full')"><span class="price">{{ helpers.translate(_TRANSLATION.views.case.open.open) }}</span></button>
                        <button class="btn btn_action btn_open_quick" @click="open('quick')">{{ helpers.translate(_TRANSLATION.views.case.open.open_quick) }}</button>
                    </div>
                </div>
            </div>

            <CasesOpenDrops :items="items"></CasesOpenDrops>
        </div>
    </transition>

    <transition name="slide-down" mode="out-in">
        <div v-if="rouletteSpinned">
            <div class="case-body" style="padding: 40px; min-height: auto;">
                <!-- If cases to open <= 3 -->
                <div v-if="openAmount <= 3" class="w-100">
                    <div class="roulette-flex">
                        <div class="roulette w-100" v-for="index in openAmount">
                            <div class="case-roulette">
                                <div class="case-roulette__spinner">
                                    <div :data-id='item.id' class="case-roulette__item"
                                        v-for="(item, key) in itemsFilled[index]" :class='"rare_" + item.rarity'>
                                        <img :src="item.image" alt="" class="case-roulette__image">
                                    </div>
                                </div>
                                <div class="case-roulette__shadow"></div>
                            </div>
                            <div class="roulette__line"></div>
                        </div>
                    </div>
                </div>
                <!-- If cases to open = 5 -->
                <div class="roulette-flex" v-if="openAmount == 5">
                    <div class="roulette" v-for="index in openAmount">
                        <div class="case-roulette">
                            <div class="case-roulette__spinner">
                                <div :data-id='item.id' class="case-roulette__item"
                                    v-for="(item, key) in itemsFilled[index]" :class='"rare_" + item.rarity'>
                                    <img :src="item.image" alt="" class="case-roulette__image">
                                </div>
                            </div>
                            <div class="case-roulette__shadow"></div>
                        </div>
                        <div class="roulette__line"></div>
                    </div>
                </div>

                <!-- If cases to open = 10 -->
                <div class="roulette-flex roulette-flex_10" v-if="openAmount == 10">
                    <div class="roulette" v-for="index in openAmount">
                        <div class="case-roulette">
                            <div class="case-roulette__spinner">
                                <div :data-id='item.id' class="case-roulette__item"
                                    v-for="(item, key) in itemsFilled[index]" :class='"rare_" + item.rarity'>
                                    <img :src="item.image" alt="" class="case-roulette__image">
                                </div>
                            </div>
                            <div class="case-roulette__shadow"></div>
                        </div>
                        <div class="roulette__line"></div>
                    </div>
                </div>
            </div>

            <CasesOpenDrops :items="items"></CasesOpenDrops>
        </div>
    </transition>

    <transition name="fade-fast" mode="out-in">
        <div class="win" v-if="openFinished">
            <div class="win__items">
                <div class="win__item" v-for="index in openAmount">
                    <div class="win__item-body" :class='"rare_"+wins[index].rarity'>
                        <img :src='wins[index].image' class="win__image">
                    </div>
                    <transition name="fade-fast">
                        <button class="btn btn_action btn_action_transparent win__item-btn" @click="sell(dropsSaved[index].id, index)" v-if="!dropsSaved[index].sold">{{ helpers.translate(_TRANSLATION.views.case.open.sell_for) }} {{ wins[index].item_global_sell_price }} {{ wins[index].price_global['currency'] }}</button>
                    </transition>
                </div>
            </div>

            <button class="btn btn_action" id="win-continue" @click="resetCase()">{{ helpers.translate(_TRANSLATION.views.case.open.continue) }}</button>
        </div>
    </transition>
</template>
