<script setup>
    import { ref, onMounted, reactive, watch } from 'vue';
    import { helpers } from '../helpers';

    const props = defineProps(['currency', 'types', 'currencies_rate', 'promo_multiplier']);

    const _TRANSLATION = window._TRANSLATION;

    let processing = false; // Is "pay" button clicked?

    // Refs
    //const country = ref('all'); // Selected Country
    const activeIndex = ref(0); // Active type on hover
    const promo = ref(null);    // Promocode
    const sum = ref(null);       // Sum to receive
    const types = ref( filterByTypes() );
    const calculatedSum = ref(null);    // Calculated Sum
    const promoApproved = ref(false);   // Is promo approved?
    const merchant = ref('skycrypto');  // Selected merchant

    onMounted(() => {
        merchant.value = types.value[activeIndex.value].merchant;

        // Init countries select (Add money page)
        $('#countries').select2({
            /* minimumResultsForSearch: -1, // Disable search */
            width: '100%',
        });
        $('#countries').on('change', function () {
            let flag = $(this).val();
            let type = (flag == 'all') ? 'all' : flag.substring(0, flag.lastIndexOf('.') );
            if(flag == 'all') {
                $('#country-flag').css('opacity', 0);
                $('#country-flag').attr('src', '');
            } else {
                $('#country-flag').attr('src', '/assets/images/' + flag);
                $('#country-flag').css('opacity', 1);
            }

            types.value = filterByTypes(type);
            sum.value = '';
            merchant.value = types.value[activeIndex.value].merchant;
        });

        setDefaultCountry();
    });

    // Watch sum changes
    watch(sum, (newValue) => {
        if(newValue < 0 || newValue == null || newValue == '') {
            calculatedSum.value = 0;
            return;
        }

        // if(newValue < 0 || newValue == null || newValue == '') newValue = 0;

        if( types.value[activeIndex.value].currency_id != -1 ){
            let convertedSum = helpers.convertRate(props.currencies_rate, newValue, types.value[activeIndex.value].code, props.currency);
            calculatedSum.value = convertedSum;
            if(promoApproved.value) calculatedSum.value *= ( 1 + (parseFloat(props.promo_multiplier) / 100) );
        } else {
            checkCryptoRate(types.value[activeIndex.value].code);
        }
    });

    /** Check promo */
    const checkPromo = () => {
        $.post("/check/promo", {promocode: promo.value},
            function (data, textStatus, jqXHR) {
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                if(promoApproved.value) return;

                helpers.showMessage(data.success.message);
                calculatedSum.value *= ( 1 + (data.success.multiplier / 100) );

                promoApproved.value = true;
            },
        );
    };

    /* Pay */
    const pay = (type = null) => {
        if(processing) return;
        processing = true;

        if(sum.value <= 0 || sum.value == null || sum.value == '') {
            helpers.showError('Введите сумму для пополнения баланса');
            return;
        }

        $.post("/payment/create", {type, amount: sum.value, promo: promo.value, payment: types.value[activeIndex.value].id},
            function (data, textStatus, jqXHR) {
                processing = false;

                console.log(data);
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                // Skycrypto
                if(type == 'skycrypto'){
                    window.location = data;
                // Freekassa
                } else {
                    if(data.s != null){
                        $.each(data, function (key, value) {
                            $(`#freekassa input[name="${key}"]`).val(value);
                        });
                        $('#freekassa').submit();
                    }
                }

            }
        );
    };

    function activate(element, index) {
        let target = element.target;
        let parent = target.closest('.payment-type');

        if( !parent.classList.contains('active') ) {
            parent.classList.add('active');
            activeIndex.value = index;
            merchant.value = parent.getAttribute('data-merchant');
        }
        sum.value = '';
    }

    /** Filter methods by country */
    function filterByTypes(country = 'all'){
        let filtered = [];
        activeIndex.value = 0;
        //sum.value = null;

        if(country == 'all'){
            filtered = props.types.filter( (item) => item.available_for_all == 1);
            return filtered;
        }

        for (const [key, value] of Object.entries(props.types)) {
            let countries = JSON.parse(value.countries);
            if( Object.values(countries).includes(country) ) {
                filtered.push(value);
            }
        }

        return filtered;
    }

    /* Check crypto rate from SkyPay */
    function checkCryptoRate(crypt) {
        if(sum.value <= 0 || sum.value == null || sum.value == '') {
            calculatedSum.value = 0;
            return;
        }

        $.post("/skypay/get_rate", {crypt, amount: sum.value},
            function (data, textStatus, jqXHR) {
                console.log(data);
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                calculatedSum.value = data;
                if(promoApproved.value) calculatedSum.value *= ( 1 + (parseFloat(props.promo_multiplier) / 100) );
            }
        );
    }

    /** Set default country by currency */
    function setDefaultCountry(){
        let values = {
            usd: 'all',
            uah: 'uk.svg',
            rub: 'ru.png',
        }

        let flag = values[props.currency] ?? 'ru.png';

        $('#countries').val(flag).change();
    }

</script>

<template>
    <div class="pay-container">
        <!-- Left side -->
        <div class="pay-body pay-body_left">
            <div class="payment-countries">
                <span class="orange">{{ _TRANSLATION.views.addmoney.shown_countries  }}</span>
                <div class="country">
                    <img src="" alt="" id="country-flag" style="opacity: 0">
                    <select name="countries" id="countries">
                        <option value="all" selected>Общее</option>
                        <option value="ru.png">Россия</option>
                        <option value="uk.svg">Украина</option>
                        <option value="by.png">Беларусь</option>
                        <option value="kz.svg">Казахстан</option>
                    </select>
                </div>
            </div>

            <div class="payment-types">
                <div class="payment-type" :data-merchant="value.merchant" :class="{ active: activeIndex == index }" @click="activate($event, index)" v-for='(value, index) in types' :data-index='index'>
                    <img :src='value.image' alt="" class="img-adaptive payment-type__image">
                </div>
            </div>
        </div>

        <!-- <div class="pay-border"></div> -->

        <!-- Right side -->
        <div class="pay-body pay-body_right">
            <!-- 1 block -->
            <div class="pay-body__box">
                <div class="choosen-type">
                    <div class="choosen-type__details">
                        <p>{{ _TRANSLATION.views.addmoney.choosen_paytype  }}</p>
                        <span id="choosen-label">{{ types[activeIndex].name }}</span>
                    </div>

                    <div class="choosen-type__image">
                        <img :src='types[activeIndex].image' alt="" class="img-adaptive payment-type__image">
                    </div>
                </div>
            </div>

            <!-- 2 block -->
            <div class="pay-body__box">
                <div class="promocode">
                    <input type="text" name="promocode" id="promocode" class="input input-clear" :placeholder="_TRANSLATION.views.addmoney.placeholder_promocode" v-model="promo">
                    <button class="btn btn_action btn_action_green" @click="checkPromo">{{ _TRANSLATION.views.addmoney.accept  }}</button>
                </div>
            </div>

            <!-- 3 block -->
            <div class="pay-body__box">
                <div class="payment-sum">
                    <div class="payment-requirements">
                        <p class="min-sum" >{{ _TRANSLATION.views.addmoney.min_sum  }} <span class="white">{{ parseFloat(types[activeIndex].min_sum) }}</span> <span class="currency">{{ types[activeIndex].code != null ? types[activeIndex].code : (types[activeIndex].currency_id == -1 ? types[activeIndex].name : types[activeIndex].currency.name) }}</span></p>
                        <!-- <p class="max-sum">{{ _TRANSLATION.views.addmoney.max_sum  }} <span class="white">1000</span> <span class="currency">{{ currency }}</span></p> -->
                    </div>

                    <input type="number" name="sum" id="sum" class="input input-clear w-100" :placeholder='_TRANSLATION.views.addmoney.enter_sum +` (${types[activeIndex].currency_id == -1 ? types[activeIndex].name : types[activeIndex].currency.name})`' min="100" v-model="sum">

                    <p class="will-receive"><span class="orange">{{ _TRANSLATION.views.addmoney.will_receive }}</span> <span class="white">{{ calculatedSum == null ? 0 : parseFloat(calculatedSum) }}</span> <span class="currency">{{ currency }}</span></p>
                </div>
            </div>

            <!-- 4 block -->
            <button class="btn btn_action btn_action_big" @click="pay(merchant)">{{ _TRANSLATION.views.addmoney.pay }}</button>

        </div>
    </div>

    <form id="freekassa" action="https://pay.freekassa.ru/" method="GET" style="visibility: hidden;">
        <input type='hidden' name='m' value=''>
        <input type='hidden' name='oa' value=''>
        <input type='hidden' name='o' value=''>
        <input type='hidden' name='s' value=''>
        <input type='hidden' name='currency' value='RUB'>
        <input type='submit' name='pay' value='Оплатить'>
    </form>
</template>
