<script setup>
import { onMounted, ref } from 'vue';
import { register } from 'swiper/element/bundle';

const props = defineProps(['items']);
console.log(props.items);

// Refs
const drops = ref(props.items);
const lastId = ref(-1);

let swiperElement, slidesAtStart;
let lastItem;

register();

onMounted(() => {
    lastItem = props.items[0];
    if (lastItem != null) lastId.value = lastItem.id;

    swiperElement = document.querySelector('swiper-container');
    slidesAtStart = document.querySelectorAll('swiper-slide').length;

    setInterval(getDrops, 3000);
});

function getDrops() {
    $.post("/live/check", { last_id: lastId.value },
        function (data) {
            console.log(data);
            if (data.error != null) return;

            lastId.value = data.success.last_id;

            let newSlides = [];
            $.each(data.success.items, function (index, value) {
                newSlides.push(`
                    <swiper-slide class="live-drop rare_${value.item.rarity}">
                        <div class="line"></div>
                        <img src='${value.item.image_generated}' alt="" class="live-drop-skin img-adaptive">
                        <div class="live-drop-details">
                            <p class="weapon-name">${value.item.weapon.name}</p>
                            <p class="weapon-details">${value.item.weapon.details}</p>
                        </div>
                    </swiper-slide>
                `);
            });

            let newSlidesAmount = data.success.items.length;
/*
            if (slidesAtStart != null) swiperElement.swiper.removeSlide(slidesAtStart - 1 - newSlidesAmount); // Remove last slide
            swiperElement.swiper.prependSlide(newSlides);
            swiperElement.swiper.slideTo(0, 1000); */

            setTimeout( () => {
                    if(slidesAtStart != null) swiperElement.swiper.removeSlide(slidesAtStart-1-newSlidesAmount); // Remove last slide
                    swiperElement.swiper.prependSlide(newSlides);
                    if(newSlidesAmount < 5) {
                        swiperElement.swiper.slideTo(0, 1000);
                    } else if(newSlidesAmount < 10) {
                        swiperElement.swiper.slideTo(0, 2000);
                    } else {
                        swiperElement.swiper.slideTo(0, 2500);
                    }
            }, data.success.timeout);

        },
    );
};
</script>

<template>
    <transition name="fade" appear>
        <swiper-container slides-per-view="auto" speed="400" css-mode="true" space-between="1" effect="slide">
            <swiper-slide class="live-drop" :class='"rare_" + value.item.rarity' v-for="(value, key) in drops">
                <div class="line"></div>
                <img :src='value.item.image_generated' alt="" class="live-drop-skin img-adaptive">
                <div class="live-drop-details">
                    <p class="weapon-name">{{ value.item.weapon.name }}</p>
                    <p class="weapon-details">{{ value.item.weapon.details }}</p>
                </div>
            </swiper-slide>
        </swiper-container>
    </transition>
</template>
