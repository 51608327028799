<script setup>
    import { ref, reactive, onMounted, nextTick } from 'vue';
    import { helpers } from '../../helpers';

    const _TRANSLATION = window._TRANSLATION;

    const props = defineProps(['drops']);

    console.log(props.drops);

    // Refs
    const isHover = ref(-1);
    const deactivatedDrops = ref([]);
    let paginatedDrops = reactive(props.drops['items'].data);
    let paginatedPages = ref(props.drops['links']);
    const skinsVisible = ref(true);
    const offer = reactive({
        message: '',
        list: {}
    });
    const dropId = ref(null);
    const choosenAlternative = ref(false);

    const blocked = ref(false);

    function mouseOver(index) {
        isHover.value = index;
    }

    function mouseLeave() {
        isHover.value = -1;
    }

    // Sell item
    const sell = (id, event) => {
        $.post(`/item/sell/${id}`, {},
            function (data, textStatus, jqXHR) {
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                //helpers.showMessage(data.success.message);
                deactivatedDrops.value.push(id);
                helpers.changeBalance(data.success.new_balance);
            }
        );
    };

    // Sell all items
    const sellAll = () => {
        $.post('/items/all/sell', {},
            function (data, textStatus, jqXHR) {
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                helpers.showMessage(data.success);
                skinsVisible.value = false;
            }
        );
    }

    // Receive item
    const receive = (id, event) => {
        deactivatedDrops.value.push(id);

        if(blocked.value) return;
        blocked.value = true;
        dropId.value = id;
        $.post(`/item/receive`, {drop_id: id},
            function (data, textStatus, jqXHR) {
                console.log(data);
                blocked.value = false;
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                if( data.offer != null ){
                    offer.message = data.message;
                    offer.list = data.offer;
                    nextTick( () => {
                        helpers.openPopup('#offer-modal');
                    } );
                    return;
                }

                helpers.showMessage(data.success);
                deactivatedDrops.value.push(id);

                showTimer(id, data.seconds_left);
            }
        );
    };

    // Receive item [Alternative]
    const receiveAlternative = () => {
        $.post(`/item/receive`, {drop_id: dropId.value, alternative_id: choosenAlternative.value},
            function (data, textStatus, jqXHR) {
                console.log(data);
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                if( data.offer != null ){
                    offer.message = data.message;
                    offer.list = data.offer;
                    nextTick( () => {
                        helpers.openPopup('#offer-modal');
                    } );
                    return;
                }

                helpers.showMessage(data.success);
                deactivatedDrops.value.push(dropId.value);
                offer.message = '';
                offer.list = {};

                showTimer(dropId.value, data.seconds_left);
            }
        );
    };

    // Choosen alternative skin
    const chooseAlternative = (id) => {
        choosenAlternative.value = id;
    };

    /* Show timer */
    function showTimer(id){
        let itemInfo = helpers.getKeyByValue(props.drops.items.data, id, "drop_id");
        if(itemInfo != null){
            let key = parseInt(itemInfo.key);
            props.drops.items.data[key].status = 3;
            props.drops.items.data[key].seconds_left = 300;
            nextTick( () => {
                helpers.startTimer(300, $(`.timer[data-id="${id}"]`) );
            });
        }
    }

    onMounted( () => {
        // Paginate
        $(document).on('click', '#pagination li', function (e) {
            e.preventDefault();
            helpers.paginate(this, (data) => {
                paginatedDrops = data.items.data;
                paginatedPages.value = data.links;
            });
        });

        // Init timers
        $('.timer').each( (index, value) => {
            let seconds = $(value).attr('data-sent');
            if(seconds > 0) helpers.startTimer(seconds, $(value));
        } );
    } );
</script>

<template>
    <div v-if="Object.keys(paginatedDrops).length > 0 && skinsVisible">
        <button class="btn btn_action" id="sell-all" style="display: block" @click="sellAll">{{ helpers.translate(_TRANSLATION.views.profile.drops.sell_all) }}</button>
        <div class="case-items">
            <div class="case-item" :data-price="value.item_price['amount_sell']" :class='"rare_"+value.rarity' v-for="(value, index) in paginatedDrops" @mouseover="mouseOver(index)" @mouseleave="mouseLeave">
                <img :src='value.image' alt="" class="img-adaptive" loading="lazy">
                <div class="case-item-details">
                    <p class="weapon-name">{{ value.weapon.name }} <span class="price">{{ value.item_price['amount_sell'] }} <span class="currency">{{ value.item_price['currency'] }}</span></span></p>
                    <p class="weapon-details">{{ value.weapon.details }}</p>
                </div>
                <div class="case-item__inactive" v-if="value.status != 0 || deactivatedDrops.includes(value.drop_id)">
                    <div v-if="value.status > 2 && value.seconds_left > 0" class="timer" :data-id="value.drop_id" :data-sent="value.seconds_left"></div>
                </div>
                <div v-else>
                    <transition name="fade-fast">
                        <div class="case-item__active" v-if="isHover == index">
                            <button class="btn btn_action" @click="sell(value.drop_id, $event)">{{ helpers.translate(_TRANSLATION.views.profile.drops.sell) }}</button>
                            <button class="btn btn_action btn_action_grey" @click="receive(value.drop_id, $event)">{{ helpers.translate(_TRANSLATION.views.profile.drops.export) }}</button>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div id="pagination" v-html="paginatedPages" class="pagination-gap"></div>
    </div>
    <div class="no-items" v-else>
        <p class="text-error">{{ helpers.translate(_TRANSLATION.views.profile.drops.empty_inventory) }}</p>
        <a href="/" class="link-clear">
            <button class="btn btn_action">{{ helpers.translate(_TRANSLATION.views.profile.drops.open_case) }}</button>
        </a>
    </div>

    <!-- POPUP ['Alternative offers'] -->
    <div id="offer-modal" class="popup mfp-hide" style="width: 900px">
        <div class="popup__head">{{ helpers.translate(_TRANSLATION.views.profile.drops.offer_popup.title) }}</div>
        <div class="popup__body messages">
            <p class="text-center" v-html="offer.message"></p>
            <div class="case-items">
                <div class="case-item" :data-price="value.price_global['amount_sell']" :class='"rare_"+value.rarity' v-for="(value, index) in offer.list" @mouseover="mouseOver(value.class + value.instance)" @mouseleave="mouseLeave">
                    <img :src='value.image_generated' alt="" class="img-adaptive" loading="lazy">
                    <div class="case-item-details">
                        <p class="weapon-name">{{ value.weapon.name }} <span class="price">{{ value.price_global['amount_sell'] }} <span class="currency">{{ value.price_global['currency'] }}</span></span></p>
                        <p class="weapon-details">{{ value.weapon.details }}</p>
                    </div>
                    <div class="alternative-choosen" v-if="choosenAlternative == value.id"></div>
                    <div v-else>
                        <transition name="fade-fast">
                            <div class="alternative-active" v-if="isHover == (value.class + value.instance)">
                                <button class="btn btn_action" @click="chooseAlternative(value.id)">{{ helpers.translate(_TRANSLATION.views.profile.drops.offer_popup.choose) }}</button>
                            </div>
                        </transition>
                    </div>

                </div>
            </div>

            <transition name="fade-fast">
                <button class="btn btn_action w-100" v-if="choosenAlternative" @click="receiveAlternative">{{ helpers.translate(_TRANSLATION.views.profile.drops.offer_popup.export) }}</button>
            </transition>
        </div>
    </div>
</template>
