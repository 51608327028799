<script setup>
    import { nextTick, ref, reactive, watch } from 'vue';
    import { useStore } from 'vuex';
    import UpgradeItems from './UpgradeItems.vue';
    import UpgradeReceiveItems from './UpgradeReceiveItems.vue';
    import { helpers } from '../../helpers';
    //import 'easy-pie-chart'; // On dev

    const _TRANSLATION = window._TRANSLATION;

    const store = useStore();

    const props = defineProps(['drops', 'currency', 'balance_global', 'max_total_selected_price', 'currencies', 'currencies_rate']);

    //console.log(props.currencies);
    const MAX_PRICE = helpers.convertRate(props.currencies_rate, props.max_total_selected_price, 'usd', props.currency.toLowerCase());
    console.log(MAX_PRICE);

    let chart = null,
        chartSelector = null;
    let rotated = false,
        animationStarted = false;
    let parent, parentInventory;

    let circleSize = (window.screen.width < 768) ? 170 : 200;

    const DEFAULT_SKIN_IMAGE = '/assets/images/ak47.png';
    let isSingleItemClicked = true;

    /* REFS */
    const activeTab = ref('inventory');
    const emptyInventory = ref( props.drops['items'].data.length == 0 ? true : false );
    const inventoryKey = ref(0);
    const wishKey = ref(0);
    let drops = ref(props.drops);
    const skinsSelected = reactive({
        give: {
            selected: false,
            image: DEFAULT_SKIN_IMAGE,
            images_all: {}
        },
        receive: {
            selected: false,
            image: DEFAULT_SKIN_IMAGE
        },
    });
    const multiplier = ref(1.5);    // Default price multiplier
    const totalSelected = reactive({
        sum: 0,
        items: {
            give: {},
            receive: null
        }
    });
    const upgradeSuccess = ref(null);
    const balance = ref(null);
    const approvedBalance = ref(null);
    const mirrorChance = ref(0);
    const isOpening = ref(false);

    console.log(props.balance_global);

    // Watch active tab changes
    watch(activeTab, (newActiveTab, oldActiveTab) => {
        reset();
    });

    // Watch balance changes
    watch(balance, (newBalance) => {
        if(newBalance < 0) {
            balance.value = 0;
        } else if( balance.value > props.balance_global.amount ) {
            balance.value = props.balance_global.amount;
        }
    });

    //On dev it should be easyPieChart

    /* Mirror chance */
    const mirror = () => {
        if(isOpening.value) return;
        let percent = Number.parseInt(chartSelector.getAttribute('data-percent'));
        let rotationAngle = 360 - (percent * 3.6);

        document.querySelector('.chart canvas').remove();
        chart = new easyPieChart(chartSelector, {
            size: circleSize,
            barColor: "#FAA05F",
            scaleLength: 0,
            lineWidth: 8,
            trackColor: "#363745",
            lineCap: "round",
            animate: false,
            rotate: !rotated ? rotationAngle : 0
        });

        rotated = !rotated;
        mirrorChance.value = rotated == true ? 1 : 0;
    }

    /* Upgrade */
    const upgrade = () => {
        if(typeof startSong != 'undefined') startSong.play();

        if( maxSelectedPriceReached(totalSelected.sum) ) {
            helpers.showError(helpers.translate(_TRANSLATION.views.upgrade.process.limit_price_reached) + ` <b>${MAX_PRICE} ${props.currency}</b>`);
            return;
        }

        if(isOpening.value) return;
        isOpening.value = true;

        const data = {
            receive: totalSelected.items.receive,
            mirror: mirrorChance.value
        };
        // If active tab is SKINS
        if( activeTab.value == 'inventory' ) {
            data.items = Object.keys(totalSelected.items.give);
            if(data.items.length == 0) {
                isOpening.value = false;
                helpers.showError( helpers.translate(_TRANSLATION.views.upgrade.process.choose_skin) );
                return;
            }

            if(data.receive == null) {
                isOpening.value = false;
                helpers.showError( helpers.translate(_TRANSLATION.views.upgrade.process.skin_upgrade_empty) );
                return;
            }
        // If active tab is balance
        } else {
            data.balance = approvedBalance.value;
        }

        $.post("/upgrade/process", data,
            function (data) {
                console.log(data);
                if(data.error != null){
                    isOpening.value = false;
                    helpers.showError(data.error);
                    return;
                }

                if(data.type != null && data.type == 'error'){
                    isOpening.value = false;
                    helpers.showError(data.message);
                    return;
                }

                // Based on CSS's animation-duration property
                // 0.12deg per ms (Or 120 deg per sec with 3 sec duration total)
                let rotationWinAngle = (data.win_chance * 3.6);
                //let animationDuration = ( (rotationWinAngle / 120) * 1000 ) + 3000;   // For 3 sec duration
                //let animationDuration = ((rotationWinAngle / 180) * 1000) + 4000;

                //let animationDuration = ((rotationWinAngle * 2 / 360 ) * 1000) + 4000;  // For 2 sec duration



                $({deg: 0}).animate({deg: 360 * 3 + rotationWinAngle}, {
                    duration: 6000,
                    easing: 'easeInOutCubic',
                    step: function(now) {
                        $('#upgrade-chance').css({
                            transform: 'rotate(' + now + 'deg)'
                        });
                    },
                    complete: function() {
                        setTimeout( () => {
                            upgradeSuccess.value = data.type == 'success' ? true : false;

                            if(typeof resultSong != 'undefined') resultSong.play();

                            if( activeTab.value == 'balance' ) {
                                nextTick( () => {
                                    helpers.changeBalance(data.new_balance);
                                } );
                            }

                            setTimeout(() => {
                                //animationStarted = false;
                                //$('#upgrade-chance').removeClass('animation-started');
                                resetSkinsStyles('.upgrade__list-your');
                                reset();
                                emptyInventory.value = data.drops.items.data.length == 0 ? true : false

                                drops.value = data.drops;
                            }, 2500);

                        }, 1500);
                    }
                }, 'ease-in-out');

            },
        );

    };

    /** [GIVE] On skin choosen */
    const onSkinGiveChoosen = function(event) {
        parentInventory = event.target.closest('.case-item');

        let parentClasses = parentInventory.classList;
        let dataId = parentInventory.dataset.id;
        let price = parseFloat( parentInventory.getAttribute('price'));
        resetSkinsStyles();

        // If item was selected before
        if( parentClasses.contains('selected') ) {
            parentClasses.remove('selected');

            delete totalSelected.items.give[dataId];
            totalSelected.sum -= price;
            if(totalSelected.sum <= 0) totalSelected.sum = 0;

            if( countAddedItems() <= 0 ) {
                skinsSelected.give.selected = false;
                skinsSelected.receive.selected = false;
                skinsSelected.receive.image = DEFAULT_SKIN_IMAGE;
                skinsSelected.give.images_all = {};
            } else {
                delete skinsSelected.give.images_all[dataId];

                let lastImageKey = helpers.getLastKey(skinsSelected.give.images_all);
                if(lastImageKey != null) skinsSelected.give.image = skinsSelected.give.images_all[lastImageKey];

                if(isSingleItemClicked) loadAvailableItems(parentInventory);
            }
        } else {
            let tempPrice = totalSelected.sum + price;

            if( maxSelectedPriceReached(tempPrice) ) {
                helpers.showError(helpers.translate(_TRANSLATION.views.upgrade.process.limit_price_reached) + ` <b>${MAX_PRICE} ${props.currency}</b>`);
                return;
            }

            totalSelected.sum += price;
            totalSelected.items.give[dataId] = parentInventory;

            parentClasses.add('selected');

            skinsSelected.give.image = parentInventory.querySelector('img').src;
            skinsSelected.give.images_all[dataId] = skinsSelected.give.image;
            if(isSingleItemClicked) loadAvailableItems(parentInventory);
        }
    }

    /* [RECEIVE] On skin choosen */
    const onSkinChoosen = function(event, type) {
        parent = event.target.closest('.case-item');

        let parentClasses = parent.classList;
        let dataId = parent.dataset.id;

        // If item was selected before
        if( parentClasses.contains('selected') ) {
            parentClasses.remove('selected');

            skinsSelected.receive.selected = false;
            totalSelected.items.receive = null;

        } else {
            resetSkinsStyles();
            parentClasses.add('selected');

            //totalSelected.items.receive = parent;
            totalSelected.items.receive = dataId;

            calculatePercentage(activeTab.value);
        }
    };

    // Count added items
    const countAddedItems = () => Object.keys(totalSelected.items.give).length;

    /* Change multiplier amount */
    const changeMultiplier = (event) => {
        let clickedSelector = event.target;
        let amount = Number.parseFloat(clickedSelector.getAttribute('amount'));
        multiplier.value = (amount == null || amount > 10) ? 1.5 : amount;
        skinsSelected.receive.selected = false;

        $('#choice li').removeClass('active');
        clickedSelector.classList.add('active');

        if(activeTab.value == 'inventory') {
            loadAvailableItems();
        } else {
            loadAvailableItemsByBalance();
        }

        //inventoryKey.value++;
        wishKey.value++;
    };

    /* Load available items */
    const loadAvailableItems = (parent) => {
        //skinsSelected.give.selected = false;

        let itemsGive = Object.keys(totalSelected.items.give);
        if(itemsGive.length == 0) return;

        setTimeout( () => {
            const data = {
                multiplier: multiplier.value,
                items: Object.keys(totalSelected.items.give)
            };
            $.get("/upgrade/load", data,
                function (data) {
                    //console.log(data);
                    if(data.error != null){
                        helpers.showError(data.error);
                        return;
                    }

                    store.commit('fill', data); // Save available items in Vuex Store

                    skinsSelected.give.selected = true;
                    skinsSelected.receive.selected = false;

                    console.log(data);
                    wishKey.value++;
                    //skinsSelected.give.image = parent.querySelector('img').src;
                }
            );
        }, 500);
    };

    // Load available items by balance
    const loadAvailableItemsByBalance = () => {
        let data = {
            multiplier: multiplier.value,
            balance: approvedBalance.value
        };
        $.get("/upgrade/load/balance", data,
            function (data) {
                //console.log(data);
                if(data.error != null){
                    helpers.showError(data.error);
                    return;
                }

                store.commit('fill', data); // Save available items in Vuex Store
                skinsSelected.give.selected = true;
                console.log(data);
                wishKey.value++;
                //skinsSelected.give.image = parent.querySelector('img').src;
            }
        );
    };

    // Calculate percentage
    const calculatePercentage = (type = 'inventory') => {
        setTimeout( () => {
            let data = {
                receive: totalSelected.items.receive,
            };
            if(type == 'inventory'){
                data.items = Object.keys(totalSelected.items.give);
            } else {
                data.balance = approvedBalance.value;
            }

            $.post("/upgrade/calculate", data,
                function (data) {
                    console.log(data);
                    if(data.error != null){
                        helpers.showError(data.error);
                        return;
                    }

                    skinsSelected.receive.image = parent.querySelector('img').src;
                    skinsSelected.receive.selected = true;

                    nextTick( () => {
                        chartSelector = document.querySelector('.chart');
                        if (chartSelector != null) {
                            chartSelector.innerHTML = "";

                            document.getElementById('win-chance').querySelector('.chance').innerHTML = data;
                            chartSelector.dataset.percent = data;

                            chart = new easyPieChart(chartSelector, {
                                size: circleSize,
                                barColor: "#FAA05F",
                                scaleLength: 0,
                                lineWidth: 8,
                                animate: 1000,
                                trackColor: "#363745",
                                lineCap: "round",
                            });
                        }
                    } );

                }
            );
        }, 500);
    };

    // Remove all selected
    const resetSkinsStyles = (selector = '.upgrade__list-proposition') => {
        document.querySelector(selector).querySelectorAll('.case-item.selected').forEach( (value, key) => {
            value.classList.remove('selected');
        });
    }

    // Reset
    const reset = () => {
        totalSelected.sum = 0;
        totalSelected.items.give = {};
        totalSelected.items.receive = null;

        skinsSelected.give.selected = false;
        skinsSelected.give.image = DEFAULT_SKIN_IMAGE;
        skinsSelected.give.images_all = {};

        inventoryKey.value++;
        wishKey.value++;

        [skinsSelected.give.selected, skinsSelected.receive.selected] = [false, false];
        [skinsSelected.give.image, skinsSelected.receive.image] = [DEFAULT_SKIN_IMAGE, DEFAULT_SKIN_IMAGE];
        upgradeSuccess.value = null;
        balance.value = null;
        approvedBalance.value = null;
        isOpening.value = false;
        mirrorChance.value = 0;
    }

    // Add balance to use
    const useBalance = () => {
        // Temp disable balance
        helpers.showError(_TRANSLATION.global.temp_disabled);
        return;
        // Temp disable balance

        approvedBalance.value = balance.value;
        if(approvedBalance.value < 1) {
            helpers.showError( helpers.translate(_TRANSLATION.views.upgrade.process.bad_balance) );
            return;
        } else if(approvedBalance.value > props.balance_global.amount){
            helpers.showError( helpers.translate(_TRANSLATION.views.upgrade.process.greater_than_balance) );
            return;
        }

        skinsSelected.receive.selected = false;
        loadAvailableItemsByBalance();
    };

    /* Watch Choosen Skins */
    const watchChoosenSkins = (parentSelector, itemsFilled) => {
        nextTick( () => {
            $(`${parentSelector} .case-item`).removeClass('selected');
            if(typeof itemsFilled === 'object') {
                $.each(itemsFilled, function (key, value) {
                    $(`${parentSelector} .case-item[data-id="${key}"]`).addClass('selected');
                });
            } else {
                $(`${parentSelector} .case-item[data-id="${itemsFilled}"]`).addClass('selected');
            }

        });
    }

    /** Calculate total selected price */
    const maxSelectedPriceReached = (priceTotal) => {
        return priceTotal > MAX_PRICE;
    }

    /** Choose all */
    const chooseAll = () => {
        isSingleItemClicked = false;
        $('.upgrade__list-your .case-item').each( (index, value) => {
            $(value).trigger('click');
        } );
        isSingleItemClicked = true;
        loadAvailableItems(parentInventory);
    }
</script>

<template>
    <div class="upgrade">
        <div class="upgrade__selected" v-if="skinsSelected.give.selected && skinsSelected.receive.selected">
            <div class="upgrade__selected-left-corner"></div>
            <div class="upgrade__selected-left upgrade__selected-grow">
                <!-- <img src="../../../images/ak47.png" alt="" class="upgrade__selected-skin"> -->
                <transition name="fade-fast" mode="out-in" appear>
                    <img :src="DEFAULT_SKIN_IMAGE" alt="" class="upgrade__selected-skin" v-if="!skinsSelected.give.selected">
                    <img :src='skinsSelected.give.image' alt="" class="upgrade__selected-skin" v-else>
                </transition>
            </div>

            <div class="upgrade__selected-center">
                <transition name="fade-fast" mode="out-in">
                    <div class="upgrade__selected-center-progress" v-if="upgradeSuccess == null">
                        <div id="upgrade-chance">
                            <img src="../../../images/circle_start-icon.svg" alt="" class="img-adaptive dot">
                        </div>
                        <!-- CHART -->
                        <div class="chart" data-percent="0"></div>

                        <div id="win-chance">
                            <span class="chance">0</span>%
                        </div>

                        <!-- Chance mirroring -->
                        <img src="../../../images/upgrade-circle.svg" alt="" class="img-adaptive"
                            id="mirror-upgrade" @click="mirror()">
                    </div>
                    <div class="upgrade__selected-center-progress" v-else>
                        <div class="upgrade-status upgrade-status_success" v-if="upgradeSuccess === true">
                            {{ helpers.translate(_TRANSLATION.views.upgrade.process.success) }}
                        </div>
                        <div class="upgrade-status upgrade-status_failed" v-else>
                            {{ helpers.translate(_TRANSLATION.views.upgrade.process.failure) }}
                        </div>
                    </div>
                </transition>
            </div>

            <div class="upgrade__selected-right upgrade__selected-grow">
                <!-- <img src="../../../images/ak47.png" alt="" class="upgrade__selected-skin"> -->
                <transition name="fade-fast" mode="out-in" appear>
                    <img :src="DEFAULT_SKIN_IMAGE" alt="" class="upgrade__selected-skin" v-if="!skinsSelected.receive.selected">
                    <img :src='skinsSelected.receive.image' alt="" class="upgrade__selected-skin" v-else>
                </transition>
            </div>
            <div class="upgrade__selected-right-corner"></div>
        </div>

        <div class="upgrade__selected" v-else>
            <div class="upgrade__selected-left-corner"></div>
            <div class="upgrade__selected-left upgrade__selected-grow">
                <!-- <img src="/assets/images/ak47.png" alt="" class="upgrade__selected-skin"> -->
                <transition name="fade-fast" mode="out-in">
                    <img :src="DEFAULT_SKIN_IMAGE" alt="" class="upgrade__selected-skin" v-if="!skinsSelected.give.selected">
                    <img :src='skinsSelected.give.image' alt="" class="upgrade__selected-skin" v-else>
                </transition>
            </div>

            <div class="upgrade__selected-center">
                <div class="upgrade__selected-center-progress">
                    <img src="../../../images/notice-icon.svg" alt="" class="img-adaptive">
                    <span class="upgrade-notice">{{ helpers.translate(_TRANSLATION.views.upgrade.process.drop_upgrade_empty) }}</span>
                </div>
            </div>

            <div class="upgrade__selected-right upgrade__selected-grow">
                <!-- <img src="/assets/images/ak47.png" alt="" class="upgrade__selected-skin"> -->
                <transition name="fade-fast" mode="out-in">
                    <img :src="DEFAULT_SKIN_IMAGE" alt="" class="upgrade__selected-skin" v-if="!skinsSelected.receive.selected">
                    <img :src='skinsSelected.receive.image' alt="" class="upgrade__selected-skin" v-else>
                </transition>
            </div>
            <div class="upgrade__selected-right-corner"></div>
        </div>

        <div class="upgrade__details">
            <div class="upgrade__details-left">
                <span v-if="activeTab == 'balance'">{{ helpers.translate(_TRANSLATION.views.upgrade.process.choosen_balance) }} {{ balance ?? 0 }} {{ currency }}</span>
                <div v-else>
                    <span v-if="totalSelected.sum == 0">{{ helpers.translate(_TRANSLATION.views.upgrade.process.no_skin_choosen) }}</span>
                    <span v-else>{{ countAddedItems() }} {{ helpers.translate(_TRANSLATION.views.upgrade.process.items) }}: {{ totalSelected.sum.toFixed(2) }} {{ currency }}</span>
                </div>
            </div>
            <!-- <div class="upgrade__details-left" v-if="activeTab == 'balance'">Выбран баланс: {{ balance ?? 0 }} {{ currency }}</div>
            <div class="upgrade__details-left" v-if="totalSelected.sum == 0">Скин ещё не выбран</div>
            <div class="upgrade__details-left" v-else>{{ countAddedItems() }} предметов: {{ totalSelected.sum.toFixed(2) }} {{ currency }}</div> -->
            <div class="upgrade__details-center">
                <button class="btn btn_action" :class="{ 'btn_action_transparent': activeTab == 'balance' }"
                    @click="activeTab='inventory'">{{ helpers.translate(_TRANSLATION.views.upgrade.process.use_skins) }}</button>
                <!-- <button class="btn btn_action" :class="{ 'btn_action_transparent': activeTab == 'inventory' }"
                    @click="activeTab='balance'">{{ helpers.translate(_TRANSLATION.views.upgrade.process.use_balance) }}</button> -->
                    <button class="btn btn_action" :class="{ 'btn_action_transparent': activeTab == 'inventory' }"
                    @click="helpers.showError( helpers.translate(_TRANSLATION.global.temp_disabled) )">{{ helpers.translate(_TRANSLATION.views.upgrade.process.use_balance) }}</button>
            </div>
            <div class="upgrade__details-right">
                <ul class="ul-inline upgrade-modifiers" id="choice" @click="changeMultiplier($event)">
                    <li class="active" :amount="1.5">x1.5</li>
                    <li :amount="2">x2</li>
                    <li :amount="3">x3</li>
                    <li :amount="5">x5</li>
                    <li :amount="10">x10</li>
                </ul>
            </div>
        </div>

        <button class="btn" id="upgrade" @click="upgrade()">{{ helpers.translate(_TRANSLATION.views.upgrade.process.upgrade) }}</button>

        <div class="row">
            <div class="upgrade__list upgrade__list-your" style="min-height: auto" v-if="activeTab == 'balance'">
                <div class="upgrade__list-header">{{ helpers.translate(_TRANSLATION.views.upgrade.process.your_balance) }} {{ currency != null ? `(${currency})` : '' }}</div>
                <div class="upgrade__list-body">
                    <div class="upgrade__list-label">
                        <div class="from-balance">
                            <input type="number" name="balance" id="balance" class="input input-clear"
                                :placeholder='helpers.translate(_TRANSLATION.views.upgrade.process.placeholder_enter_balance)' v-model="balance" min="0">
                            <button class="btn btn_action btn_action_green" @click="useBalance">{{ helpers.translate(_TRANSLATION.views.upgrade.process.confirm) }}</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="upgrade__list upgrade__list-your" v-if="activeTab == 'inventory'">
                <div class="upgrade__list-header upgrade__list-header_flex">
                    {{ helpers.translate(_TRANSLATION.views.upgrade.process.your_inventory) }}
                    <button class="btn btn_action" @click="chooseAll">Выбрать все</button>
                </div>
                <div class="upgrade__list-body">
                    <div class="upgrade__list-label" v-if="emptyInventory">
                        <img src="/assets/images/ak47.png" alt="" class="img-adaptive">
                        <p>{{ helpers.translate(_TRANSLATION.views.upgrade.process.inventory_empty) }}</p>
                        <a href="/" class="link-clear">
                            <button class="btn btn_action">{{ helpers.translate(_TRANSLATION.views.upgrade.process.open_case) }}</button>
                        </a>
                    </div>

                    <UpgradeItems :key='inventoryKey' v-on:onSkinGiveChoosen="onSkinGiveChoosen" :drops="drops" :total-selected="totalSelected" :watch-choosen-skins="watchChoosenSkins" v-else></UpgradeItems>
                </div>

            </div>

            <div class="upgrade__list upgrade__list-proposition">
                <div class="upgrade__list-header">{{ helpers.translate(_TRANSLATION.views.upgrade.process.skins) }}</div>
                <div class="upgrade__list-body">
                    <transition name="fade-fast" mode="out-in">
                        <div v-if="skinsSelected.give.selected">
                            <UpgradeReceiveItems :key='wishKey' v-on:onSkinChoosen="onSkinChoosen" :items='totalSelected.items.give' :multiplier='multiplier' :balance="approvedBalance" :total-selected="totalSelected" :watch-choosen-skins="watchChoosenSkins"></UpgradeReceiveItems>
                        </div>

                        <div class="upgrade__list-label" v-else>
                            <img src="/assets/images/ak47.png" alt="" class="img-adaptive">
                            <p>{{ helpers.translate(_TRANSLATION.views.upgrade.process.choose_skin_from_left) }}</p>
                        </div>
                    </transition>

                    <!-- <UpgradeReceiveItems v-on:onSkinChoosen="onSkinChoosen" v-if="skinsSelected.give.selected"></UpgradeReceiveItems>
                    <transition name="fade-fast" mode="out-in" v-else>
                        <div class="upgrade__list-label">
                            <img src="/assets/images/ak47.png" alt="" class="img-adaptive">
                            <p>Выберите скин слева</p>
                        </div>
                    </transition> -->
                </div>
            </div>
        </div>

        <div class="row" style="display: none;">
            <div class="upgrade__list upgrade__list-your" style="min-height: auto">
                <div class="upgrade__list-header">{{ helpers.translate(_TRANSLATION.views.upgrade.process.your_balance) }}</div>
                <div class="upgrade__list-body">
                    <div class="upgrade__list-label">
                        <!--                         <img src="/assets/images/ak47.png" alt="" class="img-adaptive">
                <p>В Вашем инвентаре нет предметов для апгрейда</p>
                <button class="btn btn_action">Открыть кейс</button> -->
                        <div class="from-balance">
                            <input type="number" name="balance" id="balance" class="input input-clear"
                                :placeholder='helpers.translate(_TRANSLATION.views.upgrade.process.placeholder_enter_balance)'>
                            <button class="btn btn_action btn_action_green">{{ helpers.translate(_TRANSLATION.views.upgrade.process.confirm) }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="upgrade__list upgrade__list-proposition">
                <div class="upgrade__list-header">СКИНЫ</div>
                <div class="upgrade__list-body">
                    <div class="upgrade__list-label">
                        <img src="/assets/images/ak47.png" alt="" class="img-adaptive">
                        <p>Выберите скин слева</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
