<script setup>
import { ref, reactive } from 'vue';
import ContractItems from './ContractItems.vue';
import { helpers } from '../../helpers';

const props = defineProps(['items']);

const _TRANSLATION = window._TRANSLATION;

// Refs
const contractCells = ref([]);
const contractItems = ref([]);
const win = ref(false);
const addedItems = reactive({});
const priceRange = reactive({
    min: 0,
    max: 0,
    currency: 'usd'
});
const winId = ref(null);    // Win id
const dropSold = ref(false);    // Is item sold?

for (let i = 1; i <= 10; i++) {
    //contractCells.value[i] = ( i < 4 ) ? true: false; // Appended for test 1-3 cells
    contractCells.value[i] = false;
    contractCells.value[i] = '';
}

const deactivateItem = (index) => {
    if (contractCells.value[index]) {
        if(addedItems[index]) {
            let price = $( addedItems[index] ).attr('data-price');
            modifyPrice(price, 'decrement');
            addedItems[index].classList.remove('added');
            delete addedItems[index];
        }

        contractCells.value[index] = false;
        contractItems.value[index] = '';
    }
};

let closestCell = null;

// Event
const onItemStatusChanged = (event) => {
    closestCell = contractCells.value.findIndex((element) => element == false);
    if (closestCell == null) return;

    let parent = event.target.closest('.case-item');

    let price = $(parent).attr('data-price');
    priceRange.currency = $(parent).attr('data-currency');

    if (parent.classList.contains('added')) {
        let currentCell = parent.dataset.cell;
        if (currentCell != null) {
            contractCells.value[currentCell] = false;
            contractItems.value[currentCell] = '';
            delete addedItems[currentCell];
            modifyPrice(price, 'decrement');
            parent.classList.remove('added');
        }
        return;
    }

    if (closestCell == -1) return;

    modifyPrice(price, 'increment');

    parent.classList.add('added');
    parent.setAttribute('data-cell', closestCell);
    let image = parent.querySelector('img').src;

    let parentData = {
        img: `<img src="${image}" alt="" class="contract__item-icon">`,
        price: '<span class="contract__item-price">' + $(parent).attr('data-price') + '<span class="currency">' + $(parent).attr('data-currency') + '</span></span>'
    }

    addedItems[closestCell] = parent;
    contractCells.value[closestCell] = true;
    contractItems.value[closestCell] = `${parentData.img} ${parentData.price}`;
};

// Create contract
const createContract = () => {

    if (Object.keys(addedItems).length < 3 || Object.keys(addedItems).length > 10) {
        helpers.showError( helpers.translate(_TRANSLATION.views.contract.create.fill_min) );
        return;
    }

    let skins = {};
    for (const [key, value] of Object.entries(addedItems)) {
        let id = $(value).attr('data-id');
        if (id == null) continue;
        skins[key] = id;
    }

    $.post("/contract/create", { skins: skins },
        function (data, textStatus, jqXHR) {
            console.log(data);
            if (data.error != null) {
                helpers.showError(data.error);
                return;
            }


            if(typeof resultSong != 'undefined') resultSong.play();

            setTimeout(() => {
                win.value = data.success;
                winId.value = data.win_id;
            }, 1000);

        },
    );
};

// Increment Price
const modifyPrice = (price, mode = 'increment') => {
    console.log(price);

    if(mode == 'increment') {
        priceRange.min += ( price * (1 - (50/100) )  );
        priceRange.max += ( price * (1 + (200/100) )  );
    } else {
        priceRange.min -= ( price * (1 - (50/100) ) );
        priceRange.max -= ( price * (1 + (200/100) ) );
    }

    /* priceRange.min = Number.parseFloat(priceRange.min).toFixed(2);
    priceRange.max = Number.parseFloat(priceRange.max).toFixed(2); */

    for (let [key, value] of Object.entries(priceRange)) {
        if(key == 'currency') continue;
        priceRange[key] = parseFloat(value.toFixed(2));
        if(value < 0) priceRange[key] = 0;
    }

    console.log(priceRange);
}

// Sell Item
const sell = (id) => {
    $.post(`/item/sell/${id}`, {},
        function (data, textStatus, jqXHR) {
            if(data.error != null){
                helpers.showError(data.error);
                return;
            }

            //helpers.showMessage(data.success.message);
            dropSold.value = true;
            //dropsSaved.value[index].sold = true;
            helpers.changeBalance(data.success.new_balance);
        }
    );
};

</script>

<template>
<transition name="fade-fast" mode="out-in">
    <div v-if="!win">
        <div class="contract">
            <div class="contract__circle-first">
                <div class="contract__circle-second">
                    <div class="contract__circle-third">
                        <div class="contract__circle-fourth" v-if="Object.keys(addedItems).length > 2">
                            <p class="title">{{ helpers.translate(_TRANSLATION.views.contract.create.contract) }}</p>
                            <button class="btn btn_action" id="contract-create" @click="createContract">{{ helpers.translate(_TRANSLATION.views.contract.create.create) }}</button>
                            <span class="contract__receive">{{ helpers.translate(_TRANSLATION.views.contract.create.receive) }}</span>
                            <span class="contract__receive contract__receive-details">от <span
                                    class="orange">{{ priceRange.min }}{{ priceRange.currency }}</span> {{ helpers.translate(_TRANSLATION.views.contract.create.before) }} <span class="orange">{{ priceRange.max }}{{ priceRange.currency }}</span></span>
                        </div>
                        <div class="contract__circle-fourth" v-else>
                            <p class="title">{{ helpers.translate(_TRANSLATION.views.contract.create.contract) }}</p>
                            <div class="contract__added-amount">{{ Object.keys(addedItems).length }} {{ helpers.translate(_TRANSLATION.views.contract.create.filled_items) }}</div>
                            <span class="contract__receive" v-html="helpers.translate(_TRANSLATION.views.contract.create.add_min)"></span>
                        </div>
                        <div class="contract__position contract__position_1">
                            <span class="contract__item-index" v-if="!contractCells[1]">1</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(1)"
                                v-html="contractItems[1]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_2">
                            <span class="contract__item-index" v-if="!contractCells[2]">2</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(2)"
                                v-html="contractItems[2]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_3">
                            <span class="contract__item-index" v-if="!contractCells[3]">3</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(3)"
                                v-html="contractItems[3]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_4">
                            <span class="contract__item-index" v-if="!contractCells[4]">4</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(4)"
                                v-html="contractItems[4]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_5">
                            <span class="contract__item-index" v-if="!contractCells[5]">5</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(5)"
                                v-html="contractItems[5]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_6">
                            <span class="contract__item-index" v-if="!contractCells[6]">6</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(6)"
                                v-html="contractItems[6]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_7">
                            <span class="contract__item-index" v-if="!contractCells[7]">7</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(7)"
                                v-html="contractItems[7]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_8">
                            <span class="contract__item-index" v-if="!contractCells[8]">8</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(8)"
                                v-html="contractItems[8]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_9">
                            <span class="contract__item-index" v-if="!contractCells[9]">9</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(9)"
                                v-html="contractItems[9]">
                            </div>
                        </div>
                        <div class="contract__position contract__position_10">
                            <span class="contract__item-index" v-if="!contractCells[10]">10</span>
                            <div v-else class="contract__item-added" @click="deactivateItem(10)"
                                v-html="contractItems[10]">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ContractItems v-on:onItemStatusChanged="onItemStatusChanged" :items="items"></ContractItems>
    </div>

    <div class="win" v-else>
        <div class="win__items">
            <div class="win__item">
                <div class="win__item-body" :class='"rare_" + win.rarity'>
                    <img :src='win.image_generated' class="win__image">
                </div>
                <transition name="fade-fast">
                    <button v-if="!dropSold" class="btn btn_action btn_action_transparent win__item-btn" @click="sell(winId)">{{ helpers.translate(_TRANSLATION.views.contract.create.sell_for) }} {{
                    win.price_global['amount_sell'] }} {{ win.price_global['currency'] }}</button>
                </transition>
            </div>
        </div>

        <button class="btn btn_action" id="win-continue" onclick="location.reload()">{{ helpers.translate(_TRANSLATION.views.contract.create.continue) }}</button>
    </div>
</transition>
</template>
